import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { getBaseUrl } from 'src/utils'
import Layout from 'src/components/Layout'
import Footer from 'src/components/Footer'
import Cover from 'src/components/Cover'
import Interview from 'src/components/Interview'
import Subscribe from 'src/components/Subscribe'
import PageHelmet from 'src/components/PageHelmet'
import withLayout from 'src/components/withLayout'
import Header from 'src/components/Header'

class InterviewTemplate extends Component {
  render() {
    const {
      data: {
        interview,
        interview: {
          title,
          image,
          image: { url: imageUrl },
          description,
          slug,
          podcast,
        },
        subscribe,
        promotion,
      },
      pageContext: { originalPath },
      locale,
      changeLocale,
      ...otherProps
    } = this.props

    return (
      <Layout locale={locale}>
        <PageHelmet
          title={title}
          image={imageUrl}
          description={description}
          url={`${getBaseUrl()}${locale}${originalPath}`}
          locale={locale}
          defer={false}
        />
        <Header locale={locale} fixed {...otherProps} />

        <Cover
          locale={locale}
          image={image}
          description={description}
          title={title}
          podcast={podcast}
          slug={slug}
          changeLocale={changeLocale}
        />
        <Interview interview={interview} ad={promotion} locale={locale} />
        <Subscribe locale={locale} subscribe={subscribe} />
        <Footer locale={locale} />
      </Layout>
    )
  }
}

export const query = graphql`
  query InterviewTemplate($id: String!, $locale: String!) {
    interview: datoCmsInterview(id: { eq: $id }, locale: { eq: $locale }) {
      title
      description
      author
      photographer
      publishDate
      podcast {
        podcastTitle
        podcastGuest
        podcastCover {
          id
          fluid(maxWidth: 660, imgixParams: { fm: "jpg", auto: "compress, format" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        duration
        podcast {
          url
        }
      }
      image {
        url
        fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress, format" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      summary {
        title
        description
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      slug
    }
    subscribe: file(relativePath: { eq: "subscribe-illustration.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    promotion: allDatoCmsPromotion(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          autoplayDelay
          slides {
            id
            title
            image {
              url
            }
            link
          }
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'home',
}

export default withLayout(customProps)(InterviewTemplate)
